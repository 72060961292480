import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
// import API from "@aws-amplify/api";
import * as Yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import awsconfig from "../aws-exports";

const endpoint = awsconfig.aws_cloud_logic_custom[0].endpoint;

// API.configure(awsconfig);

const styles = {
  inputDiv: "w-full sm:w-1/2 mt-4 sm:first:mr-2 sm:last:ml-2",
  inputDivComments: "w-full mt-4",
  label: "block",
  input: "block w-full border h-10 px-2",
  textarea: "block w-full border px-2"
};

const postContact = async data => {
  // console.log(data);
  // const apiName = "websitecontact";
  const path = "/contact";
  // const myInit = {
  //   body: { ...data }
  // };
  // const res = await API.post(apiName, path, myInit);
  const res = await fetch(`${endpoint}${path}`, {
    method: "post",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
  const resData = await res.json();
  return resData;
};

const contactSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  company: Yup.string(),
  phone: Yup.string(),
  comments: Yup.string()
});

const ContactForm = ({ toggleSkill, selectedSkills }) => {
  const { register, handleSubmit, errors, reset, formState } = useForm({
    mode: "onBlur",
    validationSchema: contactSchema
  });

  const [success, setSuccess] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async data => {
    setSuccess(null);
    try {
      const captcha = await executeRecaptcha("contactForm");
      const response = await postContact({
        ...data,
        captcha,
        skills: selectedSkills
      });
      if (response.success) {
        reset({});
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    } catch (e) {
      setSuccess(false);
    }
  };

  return (
    <section
      id="contact"
      className="w-full py-8 px-4 flex flex-col bg-gray-100 items-center h-auto text-gray-900"
    >
      <h1 className="mx-auto px-6 w-full font-header text-3xl text-center font-bold">
        Contact
      </h1>
      <p className="mt-2">
        Hire me directly on{" "}
        <a
          style={{ color: "#FF9900" }}
          className="font-bold"
          href="https://iq.aws.amazon.com/#/p/create?expertId=rogerchi"
        >
          AWS IQ
        </a>
        !
      </p>
      <form
        className="mx-auto sm:w-4/5 max-w-2xl px-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="sm:flex">
          <div className={styles.inputDiv}>
            <label className={styles.label} htmlFor="name">
              Name
              <input
                className={`${styles.input} ${
                  errors?.name?.message
                    ? `border-l-red-500`
                    : `border-l-blue-600`
                } border-l-4`}
                id="name"
                name="name"
                type="text"
                placeholder=""
                ref={register}
              ></input>
            </label>
          </div>
          <div className={styles.inputDiv}>
            <label className={styles.label} htmlFor="company">
              Company
              <input
                className={styles.input}
                id="company"
                name="company"
                type="text"
                placeholder=""
                ref={register}
              ></input>
            </label>
          </div>
        </div>
        <div className="sm:flex">
          <div className={styles.inputDiv}>
            <label className={styles.label} htmlFor="email">
              E-mail address
              <input
                className={`${styles.input} ${
                  errors?.email?.message
                    ? `border-l-red-500`
                    : `border-l-blue-600`
                } border-l-4`}
                id="email"
                name="email"
                type="text"
                placeholder=""
                ref={register}
              ></input>
            </label>
          </div>
          <div className={styles.inputDiv}>
            <label className={styles.label} htmlFor="phone">
              Phone
              <input
                className={styles.input}
                id="phone"
                name="phone"
                type="text"
                placeholder=""
                ref={register}
              ></input>
            </label>
          </div>
        </div>
        <div className="mt-4">
          <div className={styles.label} htmlFor="skillsList">
            Skills
            <div id="skillsList">
              {selectedSkills.length === 0 && (
                <div className="text-sm text-gray-700">
                  (no topics selected)
                </div>
              )}
              {selectedSkills.map(
                (skill, i, skills) =>
                  i % 2 === 0 && (
                    <div
                      key={i}
                      className="sm:flex first:mt-0 mt-2 text-sm font-bold"
                    >
                      <button
                        onClick={() => toggleSkill(skills[i])}
                        onKeyPress={() => toggleSkill(skills[i])}
                        key={skills[i]}
                        className="w-full flex justify-between sm:w-1/2 p-2 rounded bg-blue-300 cursor-pointer hover:bg-gray-300 sm:mr-2"
                      >
                        <span>{skills[i]}</span>
                        <FontAwesomeIcon
                          icon={["far", "times"]}
                          className="mt-1"
                        />
                      </button>
                      {skills[i + 1] ? (
                        <button
                          onClick={() => toggleSkill(skills[i + 1])}
                          onKeyPress={() => toggleSkill(skills[i + 1])}
                          key={skills[i + 1]}
                          className="w-full flex justify-between sm:w-1/2 p-2 rounded bg-blue-300 cursor-pointer hover:bg-gray-300 sm:ml-2 mt-2 sm:mt-0"
                        >
                          <span>{skills[i + 1]}</span>
                          <FontAwesomeIcon
                            icon={["far", "times"]}
                            className="mt-1"
                          />
                        </button>
                      ) : (
                        <div
                          key="blank"
                          className="w-full sm:w-1/2 mt-4 ml-2"
                        />
                      )}
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
        <div className={styles.inputDivComments}>
          <label className={styles.label} htmlFor="comments">
            Comments / Questions
            <textarea
              className={styles.textarea}
              id="comments"
              name="comments"
              rows={4}
              placeholder=""
              ref={register}
            ></textarea>
          </label>
        </div>
        <button
          className="block mx-auto text-white font-heading text-lg rounded bg-blue-700 px-2 py-1 mt-6 w-64 h-12 font-bold capitalize shadow-lg"
          type="submit"
          disabled={formState.isSubmitting ? true : false}
        >
          {formState.isSubmitting ? `Submitting...` : `Submit message`}
        </button>
        {success === true && (
          <div className="mt-4">Your message has been submitted.</div>
        )}
        {success === false && <div className="mt-4">Error in submitting.</div>}
        <div className="mt-4 text-gray-700 text-xs">
          This site is protected by reCAPTCHA and the Google
          <a className="text-link" href="https://policies.google.com/privacy">
            {" "}
            Privacy Policy
          </a>{" "}
          and
          <a className="text-link" href="https://policies.google.com/terms">
            {" "}
            Terms of Service
          </a>{" "}
          apply.
        </div>
      </form>
    </section>
  );
};

ContactForm.propTypes = {
  toggleSkill: PropTypes.func,
  selectedSkills: PropTypes.arrayOf(PropTypes.string)
};

export default ContactForm;
