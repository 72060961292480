import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const CertBadge = () => {
  const data = useStaticQuery(graphql`
    query {
      img2: file(
        relativePath: { eq: "solutions-architect-professional-tag_270x24.png" }
      ) {
        childImageSharp {
          fixed(width: 270, height: 24) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      img1: file(
        relativePath: { eq: "aws-certified-logo_color-horz_180x30.png" }
      ) {
        childImageSharp {
          fixed(width: 180, height: 30) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <Img fixed={data.img1.childImageSharp.fixed} />
      <br />
      <Img fixed={data.img2.childImageSharp.fixed} />
    </>
  );
};

export default CertBadge;
