import React from "react";

const About = () => {
  return (
    <section
      id="about"
      className="w-full pt-8 pb-10 px-4 flex flex-col bg-gray-900 items-center h-auto text-white"
    >
      <h1 className="mx-auto max-w-4xl px-6 w-full font-header text-3xl text-center font-bold">
        About Me
      </h1>
      <div className="mx-auto max-w-4xl px-6 mt-4 text-xl leading-tight">
        <p>
          I am a technology consultant that loves solving problems. I am always
          eager to learn the next great piece of tech.
        </p>
        <p className="mt-4">
          I have been working with Amazon Web Services for more than seven
          years, and have been a certified Solutions Architect Professional for
          the last four years.
        </p>
        <p className="mt-4">
          When I&apos;m not in front of a computer, I enjoy spending time with
          my wife and two children, and am an avid hiker, rock climber, biker,
          cooker, and chicken farmer.
        </p>
        <p className="mt-4">
          I am based in{" "}
          <span className="text-yellow-300">Poughkeepsie, NY</span>, in the
          heart of the Hudson Valley.
        </p>
      </div>
    </section>
  );
};

export default About;
