import React, { useState } from "react";

import SEO from "../components/seo";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Skills from "../components/skills";
import Header from "../components/header";
import About from "../components/about";
import ContactForm from "../components/contact";
// import Footer from "../components/footer";

const IndexPage = () => {
  const [selectedSkills, setSelectedSkills] = useState(new Set());
  const toggleSkill = skill => {
    if (selectedSkills.has(skill)) {
      const newSet = new Set([...selectedSkills]);
      newSet.delete(skill);
      setSelectedSkills(newSet);
      return;
    }
    setSelectedSkills(new Set([...selectedSkills, skill]));
  };

  return (
    <Layout>
      <SEO
        keywords={[
          `cloud`,
          `technology consulting`,
          `poughkeepsie`,
          `react`,
          `AWS`
        ]}
        titleOverride="Roger Chi Consulting | Technology & Cloud | Poughkeepsie, NY"
      />
      <Hero />
      <Header numSelected={selectedSkills.size} />
      <Skills toggleSkill={toggleSkill} selectedSkills={[...selectedSkills]} />
      <About />
      <ContactForm
        toggleSkill={toggleSkill}
        selectedSkills={[...selectedSkills]}
      />
    </Layout>
  );
};

export default IndexPage;
