import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";

const HeroText = () => (
  <>
    <p className="text-4xl md:text-5xl font-extrabold mt-4 lg:mt-0">
      {`Hi, I'm Roger.`}
    </p>
    <p className="text-xl mx-auto sm:w-9/12 lg:w-auto sm:text-2xl font-medium leading-snug mt-4">
      I am a technology consultant that specializes in the AWS Cloud and full
      stack web development.
    </p>
    <p className="text-xl mx-auto sm:w-9/12 lg:w-auto sm:text-2xl font-medium leading-snug mt-4">
      I have 15 years of experience delivering solutions to financial services
      clients and small businesses.
    </p>
    <p className="text-xl mx-auto sm:w-9/12 lg:w-auto sm:text-2xl font-medium leading-snug mt-4">
      Hire me directly on{" "}
      <a
        style={{ color: "#FF9900" }}
        href="https://iq.aws.amazon.com/#/p/create?expertId=rogerchi"
      >
        AWS IQ
      </a>
      !
    </p>
    <div className="text-lg text-blue-200 mx-auto w-3/4 sm:w-1/2 lg:w-auto sm:text-xl font-medium leading-snug mt-6 lg:mt-8 flex justify-between lg:justify-end">
      <Link className="lg:ml-10" to="/#skills">
        Skills
      </Link>
      <Link className="lg:ml-10" to="/#about">
        About
      </Link>
      {/* <div className="lg:ml-10">Blog</div> */}
      <Link className="lg:ml-10" to="/#contact">
        Contact
      </Link>
    </div>
  </>
);

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "rogerchi_background_fade.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2562, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      headshotDefault: file(relativePath: { eq: "rogerchi_headshot.jpg" }) {
        childImageSharp {
          fixed(width: 232, height: 232, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      headshotSm: file(relativePath: { eq: "rogerchi_headshot.jpg" }) {
        childImageSharp {
          fixed(width: 312, height: 312, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <section className="relative w-full py-8 px-4 flex flex-col bg-herobg items-center h-auto min-h-screen lg:hidden text-gray-100 z-50 -mt-20">
        <div className="m-auto">
          <div className="m-auto w-240px h-240px sm:hidden border-4 rounded-full overflow-hidden">
            <Img
              className="object-cover sm:hidden"
              fixed={data.headshotDefault.childImageSharp.fixed}
            />
          </div>
          <div className="hidden m-auto w-320px h-320px sm:block border-4 rounded-full overflow-hidden">
            <Img
              className="hidden object-cover sm:block"
              fixed={data.headshotSm.childImageSharp.fixed}
            />
          </div>
          <div className="mx-4 font-header text-center">
            <HeroText />
          </div>
        </div>
      </section>

      <section className="hidden relative z-40 lg:flex bg-herobg h-auto min-h-screen -mt-20">
        <BackgroundImage
          Tag="div"
          className="relative lg:flex items-center container h-screen max-h-800px mx-auto my-auto bg-right z-50"
          fluid={data.background.childImageSharp.fluid}
        >
          <div className="w-1/12"></div>
          <div className="font-header text-right w-6/12 text-gray-100 pr-4 xl:pr-0 -mt-80 xl:-mt-64">
            <HeroText />
          </div>
        </BackgroundImage>
      </section>
    </>
  );
};

export default Hero;
