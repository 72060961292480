import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CertBadge from "./certbadge";

const skillsList = [
  {
    title: "Amazon Web Services",
    description: (
      <div className="mx-auto text-center">
        <CertBadge />
      </div>
    ),
    skills: [
      {
        title: "Cloud Architecture Design",
        description: "Design..."
      },
      {
        title: "Infrastructure as Code",
        description: "CloudFormation..."
      },
      {
        title: "Serverless Solutions",
        description: "Lambda functions..."
      },
      {
        title: "Training",
        description: "Training"
      },
      {
        title: "Cloud Migrations",
        description: "On premise..."
      },
      {
        title: "Cost Optimization"
      }
    ]
  },
  {
    title: "Full stack developer",
    description:
      "I can take your idea from paper to production.  Skilled in both front- and back-end web development",
    skills: [
      { title: "JavaScript ES6" },
      {
        title: "React.js Frontend",
        description: "React, Redux"
      },
      {
        title: "Node.js Backend",
        description: "Node.js, SQL, DynamoDB..."
      },
      {
        title: "Gatsby.js Static Sites",
        description: "Gatsby..."
      },
      { title: "SQL" },
      { title: "NoSQL (DynamoDB)" }
    ]
  }
];

const SkillCard = ({ title, selected = false, onClick }) => {
  return (
    <button
      className="w-full rounded-sm first:mt-1 sm:first:mt-4 mt-4 shadow-md active:shadow-sm focus:outline-none group overflow-hidden"
      onClick={onClick}
    >
      <h3
        className={`flex justify-between font-sans font-semibold p-4 ${
          selected
            ? `bg-blue-300 group-hover:bg-blue-200`
            : `bg-gray-300 group-hover:bg-gray-200`
        }`}
      >
        <span>{title}</span>
        <div
          className={`rounded-full w-6 h-6 ${
            selected
              ? `bg-blue-400 group-hover:bg-blue-300 text-gray-800`
              : `bg-gray-400 text-gray-500 shadow-inner`
          }`}
        >
          <FontAwesomeIcon icon={["far", "check"]} />
        </div>
      </h3>
      {/* <p className="font-sans text-left p-4">{description}</p> */}
    </button>
  );
};

SkillCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const SkillSection = ({
  title,
  description,
  skills,
  toggleSkill,
  selectedSkills
}) => {
  return (
    <div className="w-full mt-8 first:mt-2 ">
      <h2 className="flex items-baseline justify-between font-bold text-lg font-sans border-b-2">
        <span>{title}</span>
        <span className="hidden sm:block font-normal text-sm text-gray-700">
          (select topics to include in contact form)
        </span>
      </h2>
      <div className="sm:flex">
        <div className="w-full sm:w-1/2 sm:pr-4 pt-4">{description}</div>

        <div className="block sm:hidden w-full mx-auto mt-4 text-right font-sm text-sm text-gray-700">
          (select topics to include in contact form)
        </div>
        <div className="w-full sm:w-1/2 sm:pl-4">
          {skills.map(skill => (
            <SkillCard
              key={skill.title}
              title={skill.title}
              description={skill.description}
              onClick={() => toggleSkill(skill.title)}
              selected={selectedSkills.includes(skill.title)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SkillSection.propTypes = {
  title: PropTypes.string.isRequired,
  skills: PropTypes.array.isRequired,
  description: PropTypes.node,
  toggleSkill: PropTypes.func.isRequired,
  selectedSkills: PropTypes.arrayOf(PropTypes.string)
};

const Skills = ({ toggleSkill, selectedSkills }) => {
  return (
    <section
      id="skills"
      className="w-full pt-8 pb-10 px-4 flex flex-col bg-gray-100 items-center text-gray-700"
    >
      <h1 className="mx-auto max-w-4xl px-6 w-full font-header text-3xl text-center font-bold">
        Skills
      </h1>
      <div className="mx-auto max-w-4xl px-6">
        {skillsList.map(skill => (
          <SkillSection
            key={skill.title}
            title={skill.title}
            description={skill.description}
            skills={skill.skills}
            toggleSkill={toggleSkill}
            selectedSkills={selectedSkills}
          />
        ))}
      </div>
    </section>
  );
};

Skills.propTypes = {
  toggleSkill: PropTypes.func.isRequired,
  selectedSkills: PropTypes.arrayOf(PropTypes.string)
};

export default Skills;
